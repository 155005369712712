var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"tw-p-0 tw-mb-2"},[_vm._v(_vm._s(_vm.$t('manage.worklets.catalog')))]),_c('ax-table',{attrs:{"footer-props":_vm.workletsFooter,"headers":_vm.workletsHeaders,"items":_vm.workletItems,"server-items-length":_vm.workletsCount,"options":_vm.tableState,"item-key":"id","no-data-text":"No worklets"},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [_c('ax-combobox',{staticClass:"mb-0",attrs:{"label":_vm.$t('worklets.search:label'),"value":_vm.queryState.q,"prepend-inner-icon":_vm.mdiMagnify,"chips":"","multiple":"","clearable":"","hide-details":"","append-icon":null},on:{"change":function (q) { return _vm.updateQueryState({ q: q }); }}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"tw-inline-block tw-leading-tight",attrs:{"title":item.name,"to":{
          path: ("worklet-catalog/" + (item.id)),
          query: {
            o: _vm.currentOrganization.id,
            frompage: '/manage/worklet-catalog',
          },
        }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.update_time",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.formatUTCToZonedTime(item.update_time)))]}},{key:"item.os_family",fn:function(ref){
        var item = ref.item;
return [_c('ax-os-icon',{staticStyle:{"height":"20px","width":"17px"},attrs:{"os-family":item.os_family}})]}},{key:"item.is_verified",fn:function(ref){
        var item = ref.item;
return [(item.is_verified === true)?_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center"},[_c('ax-logo-svg',{attrs:{"height":"20","width":"17"}})],1):_vm._e()]}},{key:"header.is_verified",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"tw-whitespace-no-wrap"},[_vm._v(_vm._s(header.text))]),_c('ax-tooltip',{attrs:{"top":"","max-width":"16rem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"tw-ml-1 tw-cursor-pointer",attrs:{"small":"","color":"primary","dense":"","aria-label":"Worklets Information"}},on),[_vm._v(" "+_vm._s(_vm.mdiInformationOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Verified indicates that the Worklet is internally tested and free of known security or performance vulnerabilities. This is no guarantee for desired results in your particular environment. ")])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('ax-table-row-actions',[_c('v-list-item',{attrs:{"to":{
            path: ("worklet-catalog/" + (item.id)),
            query: { o: _vm.currentOrganization.id },
            frompage: '/manage/worklet-catalog',
          }}},[_c('v-list-item-title',[_vm._v("View Details")])],1),_c('v-list-item',{attrs:{"to":{
            name: _vm.PolicyRouteNames.PolicyForm,
            params: { policyId: 'new' },
            query: {
              o: _vm.currentOrganization.id,
              wid: item.id,
              policy_type: 'worklet',
            },
            frompage: '/manage/worklet-catalog',
          }}},[_c('v-list-item-title',[_vm._v("Create Policy")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }